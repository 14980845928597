.edit-form input:not([type="submit"]) {
  background-color: white;
  border: 1px black solid;
  border-radius: 4px;
  padding: 5px;
  margin: 7px;
}

.edit-form input[type="checkbox"] {
  padding: 5px;
  margin: 7px;
}

@media(max-width:767px) {
  .login-forms input {
    width: 100%;
  }

  .top-form {
    padding-bottom: 20px;
  }
}

.width-max input {
  width: 100%;
}

.login-forms label {
  display: inline-block;
  width: 100px;
}

.edit-form {
  label {
    display: inline-block;
    width: 210px;
    text-align: right;
  }

  input[type="submit"] {
    margin-right: 10px;
  }
}

@media(max-width:767px) {
  .edit-form {
    input:not([type="checkbox"]) {
      width: 80%;
    }

    input[type="submit"] {
      margin-bottom: 20px;
    }

    label {
      text-align: left;
    }

    .edit-entry {
      padding-bottom: 20px;
    }
  }
}

label {
  font-weight: normal;
}

.required {
  font-size: 18px;
}
