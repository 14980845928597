.breadcrumb {
  background-color: inherit;
  padding-left: 0;
  padding-right: 0;
}

.pageinate_input_box {
  width: 56px;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .paginate_button {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}
