body {
  background-color: $light-grey;
  color: $dark-grey;
}

article {
  background-color: $white;
  padding: 20px;
}

h1 {
  font-size: 40px;
  font-weight: bold;
}

h2 {
  font-size: 22px;
  font-weight: bold;
}

a {
  color: $light-blue;
}

@media(max-width:767px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }
}

.background-lighter-grey {
  background-color: $lighter-grey;
}

.background-light-blue {
  background-color: $light-blue;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

.text-grey {
  color: grey;
}

.action-bar {
  border: 1px $light-blue solid !important;
}

.block-padding {
  padding: 20px 40px;
  border: 15px white solid;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 2378px;
}

@media(min-width:996px) {
  .resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 1464px;
  }
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
